export var  materialForm= {
    value: 'materialData',
    label: 'Material Component',
    type: 'multiline',
    model: 'materialData',
    data: [],
    tabData: [
        {
            label: 'BOM Number',
            type: 'text',
            // method: 'api',
            source: 'plantSet',
            model: 'recipe_bomNo',
            reloadFields: ['bom_Component'],
            reloadParam: 'Stlnr',
            // setModelAuto: 'bom_Component',
            // setModelValue: 'Idnrk',
            data: [],
            addRows: true,
            fieldValues: ['Stlnr'],
            takeValue: 'Stlnr',
            showValues: ['Stlnr'],
            required: true,
            disabled: true,
            size: 4,
        },
        {
            label: 'Material',
            type: 'text',
            // method: 'api',
            source: 'fetchAllBomComponent',
            model: 'bom_Component',
            // reloadFields: ['bom_Description'],
            // reloadParam: 'Idnrk',
            data: [],
            fieldValues: ['Matnr'],
            takeValue: 'Matnr',
            showValues: ['Matnr'],
            required: true,
            disabled: true,
            addFIelds: 'bom_Description',
            size: 4,
        },
        {
            label: 'Description',
            type: 'text',
            // method: 'api',
            source: 'bomDescription',
            model: 'bom_Description',
            data: [],
            takeValue: 'Maktx',
            showValue: 'Maktx',
            required: true,
            disabled: true,
            size: 4,
        },
        // {
        //     label: 'Quantity',
        //     type: 'number',
        //     model: 'recipe_Qty',
        //     required: true,
        //     disabled: true,
        //     size: 4,
        // },
    ],
  };
  