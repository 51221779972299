import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-hanacloud-modal-page',
  templateUrl: './hanacloud-modal-page.page.html',
  styleUrls: ['./hanacloud-modal-page.page.scss'],
})

export class HANACloudModalPagePage implements OnInit {

  userHost: any;
  userName: any;
  userPassword: any;
  userDatabase: any;
  databaseKeys: any = {};
  userId: any;
  port: any;
  schemaName: any;
  error: null;

  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  constructor(private modalCtrl: ModalController, public commonService: CommonService,
    private http: HttpClient, private router: Router,
    private navController: NavController) { }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem('userId'));
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  close() {
    this.modalCtrl.dismiss({
      data: this.databaseKeys
    });
  }

  createConnection() {
    if (this.userHost == null) {
      this.commonService.showToast('error', "Field required");
    } else if (this.userName == null) {
      this.commonService.showToast('error', "Field required");
    }
    else if (this.userPassword == null) {
      this.commonService.showToast('error', "Field required");
    }
    else if (this.port == null) {
      this.commonService.showToast('error', "Field required");
    }
    else if (this.schemaName == null) {
      this.commonService.showToast('error', "Field required");
    } else {
    this.commonService.dmacpresentLoading("Loading");
      let formData = {
        userId: this.userId,
        // host:"787646a4-9453-4ea4-8fd8-aec0d916fbdd.hana.prod-us10.hanacloud.ondemand.com",
        // userName:"D04A17509A9B4D34A12EC33C7F57B65D_EEO23QXY4SY45EFMHCXSD9WQH_RT",
        // password:"Bz95sYtj0fYVBeoP8lh.r9J4TgPpIYSkWOsm.94QoUB1uG9XjcvTR95XgTWrf3BXKRKYjF9X4EKNxplXmP6VrAV6FbtxBJ2c-RccLz9pEfHdgCS0CmPcmCwJ6bk88Xnm",
        // port:"443",
        // schemaName:"D04A17509A9B4D34A12EC33C7F57B65D",
        // databaseType: "hana",
        host: this.userHost,
        userName: this.userName,
        password: this.userPassword,
        port: this.port,
        databaseType: "hana",
        schemaName: this.schemaName
      }
      // console.log(formData)
      this.commonService.createConnection(formData).then((res: any) => {
        this.commonService.loadingDismiss();
        this.commonService.showToast('success', "Connection created.");
        this.databaseKeys = res;

        localStorage.setItem("activeConnection", JSON.stringify(formData));
        localStorage.setItem("connectionType", "hana");


        // console.log(this.databaseKeys)   
        this.close();
      },
      
        error => {
          if (error.status == 200) {
            this.error = error.message;
            this.commonService.showToast("error", this.error);       
            console.log(error)
          }
          if (error.status == 400) {
            this.error = error.error.msg;
            this.commonService.showToast("error", this.error);          
            console.log(error)
          }
          if (error.status == 502) {
            this.error = error.message;
            this.commonService.showToast("error", this.error);
            console.log(error)
          }
          // this.error = error.error.msg;
          // this.commonService.showToast("error", this.error);
          // console.log(error)
        })
    
  }

}}
