export var lineItem = {
    value: 'lineItem',
    label: 'Line Item',
    type: 'multiline',
    model: 'addItemArr',
    data: [],
    tabData: [{
        label: 'Material',
        model: 'Material',
        type: 'select',
        method: 'api',
        source: 'materialSet',
        data: [],
        fieldValues: ['Matnr'],
        takeValue: 'Matnr',
        showValues: ['Matnr'],
        size: 4
      },
      // { ///parameters will be passed
      //   label: 'Sales Item Category',
      //   model: 'Sales_Item_Category',
      //   type: 'select',
      //   method: 'api',
      //   source: 'itemCategory',
      //   data: {},
      //   setExternal: true,
      //   setExternalModel: 'Sales_Item_Text',
      //   setExternalValue: 'Vtext',
      //   fieldValues: ['Pstyv', 'Vtext'],
      //   takeValue: 'Pstyv',
      //   showValues: ['Pstyv'],
      //   size: 4
      // },
      // {
      //   label: 'Sales Item Text',////data will be displayed from api
      //   type: 'text',
      //   disabled: true,
      //   model: 'Sales_Item_Text',
      //   size: 4
      // },
      {
        label: 'Request Quantity',
        type: 'text',
        model: 'Request_Quantity',
        size: 4
      },
      // { 
      //   label: 'Sales Unit',
      //   model: 'Sales_Order',
      //   type: 'select',
      //   method: 'api',
      //   source: 'uomSet',
      //   data: {},
      //   fieldValues: ['Mseh3', 'Msehl'],
      //   takeValue: 'Mseh3',
      //   showValues: ['Mseh3', 'Msehl'],
      //   size: 4
      // },
      {
        label: 'Net Amount',
        type: 'text',
        model: 'Net_Amount',
        size: 4
      },
      { 
        label: 'Plant',
        type: 'select',
        method: 'api',
        source: 'plantHelpFilter',
        model: 'Plant',
        reloadFields: ['Storage_Location'],
        reloadParam: 'Werks',
        data: [],
        fieldValues: ['Werks', 'Name1'],
        takeValue: 'Werks',
        showValues: ['Werks', 'Name1'],
        size: 4
      },
      {
        label: 'Storage Location',
        type: 'selectMulti',
        method: 'api',
        source: 'storLocation',
        model: 'Storage_Location',
        data: {},
        fieldValues: ['Werks', 'Lgort', 'Lgobe'],
        takeValue: 'Lgort',
        showValues: ['Werks', 'Lgort', 'Lgobe'],
        size: 4
      },
      // { 
      //   label: 'Sales Document Reason',
      //   model: 'Sales_Document_Reason',
      //   type: 'select',
      //   method: 'api',
      //   source: 'rejectionReason',
      //   data: {},
      //   fieldValues: ['Abgru','Bezei'],
      //   takeValue: 'Abgru',
      //   showValues: ['Abgru','Bezei'],
      //   size: 4
      // }
    ]
  };
  