import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-my-sqlmodal-page',
  templateUrl: './my-sqlmodal-page.page.html',
  styleUrls: ['./my-sqlmodal-page.page.scss'],
})

export class MySQLModalPagePage implements OnInit {

  userHost: any;
  userName: any;
  userPassword: any;
  userDatabase: any;
  databaseKeys: any = {};
  userId: any;
  error: null;

  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  constructor(private modalCtrl: ModalController, public commonService: CommonService,
    private http: HttpClient, private router: Router,
    private navController: NavController) { }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem('userId'));
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  close() {
    this.modalCtrl.dismiss({
      data: this.databaseKeys
    });
  }

  createConnection() {
    if (this.userHost == null) {
      this.commonService.showToast('error', "Field required");
    } else if (this.userName == null) {
      this.commonService.showToast('error', "Field required");
    }
    else if (this.userPassword == null) {
      this.commonService.showToast('error', "Field required");
    }
    else if (this.userDatabase == null) {
      this.commonService.showToast('error', "Field required");
    } else {
    this.commonService.dmacpresentLoading("Loading");
      let formData = {
        userId: this.userId,
        userHost: this.userHost,
        userName: this.userName,
        userPassword: this.userPassword,
        userDatabase: this.userDatabase,
        databaseType: "mySQL",
        // userId:this.userId,
        // userHost:"159.223.177.89",
        // userName:"chand",
        // userPassword:"Hrportal@12345",
        // userDatabase:"HrPortal",
        // databaseType: "mySQL",      
      }
      // console.log(formData)
      this.commonService.createConnection(formData).then((res: any) => {
        this.commonService.loadingDismiss();
        this.commonService.showToast('success', "Connection created.");
        this.databaseKeys = res;
        localStorage.setItem("activeConnection", JSON.stringify(formData))
        localStorage.setItem("connectionType", "mySQL")
        this.close();
      },
        error => {
          if (error.status == 200) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);            
            console.log(error)
          }
          if (error.status == 400) {
            if(error.error.msg){
              this.error = error.error.msg;
              this.commonService.dmacshowToast("error", this.error);          
              console.log(error)
            }
            else{
              this.commonService.dmacshowToast("error", "Something went wrong!");   
            }
            }        
          if (error.status == 502) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);         
            console.log(error)
          }
        })
    }
  }

}
