export var initialForm = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
    tabData: [
      {
        label: 'Recipe Group',
        type: 'text',
        model: 'recipe_group',
        // required: true,
        disabled: true,
        size: 4,
      },
      {
        label: 'Material No.',
        type: 'text',
        model: 'recipe_materialNo',
        required: true,
        reloadFields: ['recipe_plant','recipe_prod_version','recipe_description','recipe_UOM','recipe_bomNo'],
        reloadParam: 'Matnr',
        screen: ['initial','headerData','materialData'],
        size: 4
      },
      {
        label: 'Plant',
        type: 'select',
        method: 'api',
        source: 'plantSet',
        model: 'recipe_plant',
        data: [],
        required: true,
        fieldValues: ['Werks'],
        takeValue: 'Werks',
        showValues: ['Werks'],
        size: 4,
      },
      // {
      //   label: 'Profile',
      //   type: 'text',
      //   model: 'recipe_profile',
      //   required: true,
      //   size: 4,
      // },
      {
        label: 'Profile',
        type: 'select',
        method: 'api',
        source: 'profile',
        model: 'recipe_profile',
        data: [],
        required: true,
        fieldValues: ['Profidnetz'],
        takeValue: 'Profidnetz',
        showValues: ['Profidnetz'],
        size: 4,
      },
      // {
      //   label: 'Production Version',
      //   type: 'text',
      //   model: 'recipe_prod_version',
      //   required: true,
      //   size: 4,
      // },
      {
        label: 'Production Version',
        type: 'text',
        method : 'api',
        source: 'getVersion',
        model: 'recipe_prod_version',
        data: [],
        required: true,
        fieldValues: ['Verid'],
        takeValue: 'Verid',
        showValues: ['Verid'],
        size: 4,
      },
    ],
  };
  