export var cyberSecurity = {
    value: 'cyber',
    label: 'Cyber Security',
    type: 'standard',
    tabData: [
      {
        label: "In providing the service, will you have access to any company's employee or customer information ,company's internal or confidential information- either via data sent by company to you or your company processing/storing/hosting company data on your systems?",
        type: 'radio',
        required: true,
        model: 'ifCompanyInfoAccess'
      },
      
    ]
}