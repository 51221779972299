export var headerDetails = {
    value: 'header',
    label: 'Header Details',
    type: 'standard',
    tabData: [{
        label: 'Material',
        type: 'text',
        model: 'material',
        required: true,
        disabled: true,
        size: 4
      },
      {
        label: 'Plant',
        type: 'text',
        model: 'plant',
        required: true,
        disabled: true,
        size: 4
      },
      {
        label: 'Key Date',
        type: 'text',
        model: 'keyDate',
        required: true,
        disabled: true,
        size: 4
      },
      {
        label: 'Usage',
        type: 'select',
        method: 'api',
        model: 'usage',
        source: 'tlUsage',
        required: true,
        data: [],
        fieldValues: ['Verwe', 'Txt'],
        takeValue: 'Verwe',
        showValues: ['Verwe', 'Txt'],
        size: 4,
        massKey: "Task_List_Usage",
      },
      {
        label: 'Status',
        type: 'select',
        method: 'api',
        model: 'billOfOperationsStatus',
        source: 'tlStatus',
        required: true,
        data: [],
        fieldValues: ['Plnst', 'Txt'],
        takeValue: 'Plnst',
        showValues: ['Plnst', 'Txt'],
        size: 4,
        massKey: "Status",
      },
      {
        label: 'From Lot Size',
        type: 'number',
        model: 'fromLotSize',
        required: true,
        size: 4,
        massKey: "From_Lotsize",
      },
      {
        label: 'To Lot Size',
        type: 'number',
        model: 'toLotSize',
        required: true,
        size: 4,
        massKey: "To_LotSize",
      },
      {
        label: 'Base Unit of Measure',
        type: 'select',
        method: 'api',
        model: 'billOfOperationsUnit',
        source: 'uomSet',
        required: true,
        data: [],
        fieldValues: ['Mseh3', 'Msehl'],
        takeValue: 'Mseh3',
        showValues: ['Mseh3', 'Msehl'],
        size: 4,
        massKey: "Unit_of_Measure",
      }
    ]
  };
  