import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalController,NavParams } from '@ionic/angular';


@Component({
  selector: 'app-article-hierarchy-modal',
  templateUrl: './article-hierarchy-modal.page.html',
  styleUrls: ['./article-hierarchy-modal.page.scss'],
})
export class ArticleHierarchyModalPage implements OnInit {
  @Input() article: any;
  @Input() showRangeRow: any;
  addItemArr = [{Article: '', isArticeValid: false}];
  tempArr: any = {};
  tempArrayNoRange: any = {};
  tempArrayRange: any = {};
  validationMsg = '';
  // @Output() saveClicked = new EventEmitter<any>();
  everyListValid: any;
  rangeArticleType = [{ prepack_From:  '', prepack_To: ''}];
  constructor(public commonService : CommonService,
    public modalController:ModalController,private navParams: NavParams) { }

  ngOnInit() {
    console.log(this.article, this.showRangeRow)
    this.showRangeRow = this.showRangeRow;
    this.addItemArr = [];
    if (this.showRangeRow) {
      this.addArticleRangeObj(this.article);
    } else {
      this.addMultipleArticle(this.article[0].Article);
    }
  }
  addMultipleArticle(value, validationNeeded = true) {
    value = value && value.trim();
    let arrtArray = [];
    this.addItemArr = [];
    arrtArray = value.split(' ');
    if (arrtArray && arrtArray.length) {
      arrtArray.forEach(element => {
          this.addArticleObject(element);
      });
    }
    if (validationNeeded && value) {
      this.validtateArticleList();
    }
}

addArticleObject(value) {
  this.tempArrayNoRange = {
    Article: value || '',
    isArticeValid: false,
  };
  this.addItemArr.push(this.tempArrayNoRange);
}

addArticleRangeObj( d = this.rangeArticleType) {
  d.forEach(data => {
    this.tempArrayRange = {
      prepack_From: data && data.prepack_From || '',
      prepack_To: data && data.prepack_To || '',
    };
    this.addItemArr.push(this.tempArrayRange);
  });
}
validtateArticleList(saveButtonClicked= false) {
  this.validationMsg = 'Validating...';
  const formData = this.addItemArr;
  // this.commonService.validateArticleNumber(validatePayLoad).then(
  this.commonService.validtateArticleList(formData).then(
    (res: any) => {
      if (res) {
        this.addItemArr = res;
        let isArticleValidList: any;
        isArticleValidList = this.addItemArr.map(a => a.isArticeValid);
        this.everyListValid = isArticleValidList.every(bool => bool);
        this.validationMsg = '';
        if (!this.everyListValid) {
          // this.myService.openSnackBar('Invalid entries', 'Done');
          return;
        } else if (saveButtonClicked) {
          // this.myService.openSnackBar('All entries are valid', 'Done');
          this.saveAndClose();
        }
      }
    },
    (error: any) => {
    }
  );
}
removeArticleObject(index) {
  if (this.addItemArr.length === 1) {
    return;
  }
  this.addItemArr.splice(index, 1);
}
save(): void {
  if (!this.showRangeRow) {
    this.validtateArticleList(true);
  }
  if (this.showRangeRow) {
    this.addItemArr.filter(e => delete e.Article);
  }
  if (this.showRangeRow) {
    this.saveAndClose();
  }
}
async saveAndClose() {
  
console.log(this.addItemArr)
  // const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(this.addItemArr);
  // this.saveClicked.emit(this.addItemArr);
  // this.modalController.dismiss();
}
clear() {
  this.addItemArr = [];
  this.addMultipleArticle('', false);
}
close(){
  this.modalController.dismiss();
}
}
