export var initialForm = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
    tabData: [
      {
        label: 'Chart of Account',
        type: 'select',
        model: 'chartOfAccount',
        method: 'api',
        source: 'chartOfAccount',
        // reloadFields: ['glAccount'],
        // reloadParam: 'Ktopl',
        required: true,
        data: [],
        fieldValues: ['Ktopl', 'Ktplt'],
        takeValue: 'Ktopl',
        showValues: ['Ktopl', 'Ktplt'],
        massKey: "CHART_OF_ACCOUNT",
        size: 4,
        maxLength: 35
      },
      {
        label: 'Company Code',
        type: 'select',
        method: 'api',
        model: 'companyCode',
        source: 'companyCodeHelp',
        required: true,
        reloadFieldsAllScreen: ['addStatusGroup'],
        reloadFields: ['addStatusGroup'],
        reloadParam: 'Bukrs',
        screen: ['Bank'],
        data: [],
        fieldValues: ['Bukrs','Butxt' ],
        takeValue: 'Bukrs',
        showValues: ['Bukrs','Butxt'],
        massKey: "COMPANY_CODE",
        size: 4,
        maxLength: 4
      },
      {
        label: 'G/L Account Type',
        type: 'select',
        model: 'glAccountType',
        method: 'api',
        source: 'glAccountType',
        reloadFields: ['glAccountSubType'],
        reloadParam: 'GlaccountType',
        visibilityValue: 'C',///on the basis of this value fields will get hide and show
        visibleFields: ['glAccountSubType'],///model name of fields which needs to be show
        required: false,
        data: [],
        fieldValues: ['Domname', 'DomvalueL','Ddtext'],
        takeValue: 'DomvalueL',
        showValues: ['Domname', 'DomvalueL','Ddtext'],
        massKey: "GL_ACCOUNT_TYPE",
        size: 4,
        maxLength: 25
      },
      {
        label: 'G/L Account Sub-Type',
        type: 'select',
        model: 'glAccountSubType',
        method: 'api',
        source: 'glAccountSubType',
        data: [],
        fieldValues: ['GlaccountType', 'GlaccountSubtype','Name'],
        takeValue: 'GlaccountType',
        showValues: ['GlaccountType', 'GlaccountSubtype','Name'],
        // massKey: "GL_ACCOUNT_SUBTYPE",
        visible: false,
        size: 4
      },
      {
        label: 'Account Group',
        type: 'select',
        model: 'accountGroup',
        method: 'api',
        source: 'accountGroupSet',
        required: true,
        data: [],
        fieldValues: ['Ktopl', 'Ktoks','Txt30'],
        takeValue: 'Ktoks',
        showValues: ['Ktoks', 'Txt30'],
        massKey: "ACCOUNT_GROUP",
        size: 4
      },
      {
        label: 'G/L Account',
        type: 'text',
        model: 'glAccount',
        matchData:'glRandom',
        // required: true,
        random: true,
        massKey: "GL_ACCOUNT",
        size: 4,
        maxLength: 16
      },
      // {
      //   label: 'GL Account',
      //   type: 'select',
      //   model: 'glAccount',
      //   method: 'api',
      //   source: 'glAccChartBasedNo',
      //   required: false,
      //   data: [],
      //   fieldValues: ['Sakan', 'Txt20'],
      //   takeValue: 'Sakan',
      //   showValues: ['Sakan', 'Txt20']

      // },
      {
        label: 'G/L Short Text',
        type: 'text',
        model: 'glShortText',
        required: false,
        massKey: "SHORT_TEXT",
        size: 4
      },
      {
        label: 'G/L Long Text',
        type: 'text',
        model: 'glLongText',
        required: true,
        massKey: "LONG_TEXT",
        size: 4
      }
    ],
      validations: [{
        fields: ['glAccount'],
        keys: ['GeneralLedger'],
        source: 'checkGLDuplicacy',
        model: 'GL',
        onSelect: ['glAccount'],
        size: 4
      }],
  };