export var headerForm = {
  value: 'headerData',
  label: 'Header Data',
  type: 'standard',
  tabData: [
    {
      label: 'Description',
      type: 'text',
      method: 'api',
      model: 'recipe_description',
      source: 'materialDescription',
      required: true,
      data: [],
      fieldValues: ['Maktx'],
      takeValue: 'Maktx',
      showValues: ['Maktx'],
      isEmpty: true,
      size: 4,
    },
    {
      label: 'Status',
      type: 'select',
      method: 'api',
      model: 'recipe_status',
      source: 'recipeStatus',
      required: true,
      data: [],
      fieldValues: ['Plnst', 'Txt'],
      takeValue: 'Plnst',
      showValues: ['Plnst', 'Txt'],
      size: 4,
    },
    {
      label: 'Usage',
      type: 'select',
      method: 'api',
      model: 'recipe_usage',
      source: 'recipeUsage',
      required: true,
      data: [],
      fieldValues: ['Stlan', 'Antxt'],
      takeValue: 'Stlan',
      showValues: ['Stlan', 'Antxt'],
      size: 4,
    },
    {
      label: 'From',
      type: 'number',
      model: 'fromRecipeSize',
      required: true,
      size: 4,
    },
    {
      label: 'To',
      type: 'number',
      model: 'toRecipeSize',
      required: true,
      size: 4,
    },
    {
      label: 'Unit of Measure',
      type: 'select',
      method: 'api',
      model: 'recipe_UOM',
      source: 'recipeUom',
      required: true,
      data: [],
      fieldValues: ['Meins'],
      takeValue: 'Meins',
      showValues: ['Meins'],
      size: 4,
    },
  ]
};
