export var supplierAcknowledgement = {
    value: 'supplier',
    label: 'Supplier Acknowledgement',
    type: 'standard',
    tabData: [
      {
        // label:'hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii',
        // label: 'Please select “Yes" to indicate that you have answered all the above questions to the best of your knowledge.',
        label: 'Please select the checkbox if you have answered all the questions to the best of your knowledge.',
        type: 'checkbox',
        required: true,
        default: false,
        // model: 'ifSupplierAcknowledged',
        model: 'ifSupplierAcknowledgedFalse',
      },
      
    ]
}