import { Component, OnInit,Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-partnerpopup',
  templateUrl: './partnerpopup.page.html',
  styleUrls: ['./partnerpopup.page.scss'],
})
export class PartnerpopupPage implements OnInit {
  @Input() request: any;
  TableData:any=[];
  constructor(private ModalController: ModalController, private ActivatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.TableData.push(this.request)
    console.log("request data is ",this.TableData)
  }
  closeModal() {

    this.ModalController.dismiss();
  }
  
}
