import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupplierPortalService {
  url: any = 'https://www.saphelpline.com/';
  userId :any=JSON.parse(localStorage.getItem('userId'))

  constructor(public http: HttpClient) { 
  }

  //////////////////////////

createVendorRequest(data:any){
  let formdata={
    "_id":"61de8d622d5a3a48e34a8bec",
    "requestId":data,
  }
  return new Promise((resolve, reject) => {
    this.http.post(this.url + 'RequestToVendor', formdata).subscribe(resp => {
      resolve(resp)
    }, error => {
      reject(error)
    })
  })
}
}
