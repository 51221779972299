export var basicForm = {
    value: 'header',
    label: 'Header',
    type: 'standard',
    tabData: [
      {
        label: 'Sales Order Type',
        // model: 'orderType',///ngModel
        model: 'DocType',
        type: 'select',
        method: 'api',
        required: true,
        source: 'salesDocType',///end point of api
        data: [],
        fieldValues: ['Auart', 'Bezei'],////response of api 
        takeValue: 'Auart',///value which is to be passed
        showValues: ['Auart', 'Bezei'], ///values which is to be show on screen
        size: 4
      },
      {
        label: 'Sales Organization',
        // model: 'salesOrg',
        model: 'SalesOrg',
        type: 'select',
        method: 'api',
        required: true,
        source: 'salesOrganisations',
        reloadFields: ['distributionChannel'],
        reloadParam: 'Vkorg',
        data: [],
        fieldValues: ['Vkorg', 'Vtext'],////response of api 
        takeValue: 'Vkorg',///value which is to be passed
        showValues: ['Vkorg', 'Vtext'], ///values which is to be show on screen
        size: 4
      },
      {
        label: 'Distribution Channel',
        // model: 'distributionChannel',
        model: 'DistrChan',
        type: 'select',
        method: 'api',
        required: true,
        source: 'distributionChannel',
        reloadFields: ['division'],
        reloadParam: 'Vtweg',
        extraParamKey: 'Vkorg',
        extraParamValue: 'salesOrg',
        data: [],
        fieldValues: ['Vtweg', 'Vtext'],////response of api 
        takeValue: 'Vtweg',///value which is to be passed
        showValues: ['Vtweg', 'Vtext'], ///values which is to be show on screen
        size: 4
      },
      {
        label: 'Division',
        // model: 'division',
        model: 'Division',
        type: 'select',
        method: 'api',
        required: true,
        source: 'divisionSet',
        data: [],
        fieldValues: ['Spart', 'Vtext'],////response of api 
        takeValue: 'Spart',///value which is to be passed
        showValues: ['Spart', 'Vtext'] ///values which is to be show on screen
      },
      {
        label: 'Sales Group',
        model: 'salesGroup',
        type: 'select',
        method: 'api',
        // required: true,
        source: 'salesGroup',
        data: [],
        fieldValues: ['Bezei'],////response of api 
        takeValue: 'Bezei',///value which is to be passed
        showValues: ['Bezei'], ///values which is to be show on screen
        size: 4
      },
      {
        label: 'Sales Office',
        model: 'salesOffice',
        type: 'select',
        method: 'api',
        // required: true,
        source: 'salesOffice',
        data: [],
        fieldValues: ['Vkbur','Bezei'],////response of api 
        takeValue: 'Vkbur',///value which is to be passed
        showValues: ['Vkbur','Bezei'], ///values which is to be show on screen
        size: 4
      }
    ]
  };
  