export var operationView = {
  value: 'operation',
  label: 'Operation Overview',
  type: 'multiline',
  model: 'operationOverview',
  data: [],
  tabDataStandard: [{
    label: 'Material',
    type: 'text',
    model: 'material',
    required: true,
    disabled: true,
    size: 4
  },
  {
    label: 'Plant',
    type: 'text',
    model: 'plant',
    required: true,
    disabled: true,
    size: 4
  },
  {
    label: 'Key Date',
    type: 'text',
    model: 'keyDate',
    required: true,
    disabled: true,
    size: 4
  },
  {
    label: 'Usage',
    type: 'text',
    model: 'usage',
    required: true,
    disabled: true,
    size: 4
  },
  // {
  //   label: 'Overall Status',
  //   type: 'text',
  //   model: 'status',
  //   required: false,
  //   disabled: true
  // },
  {
    label: 'From Lot Size',
    type: 'text',
    model: 'fromLotSize',
    required: true,
    disabled: true,
    size: 4
  },
  {
    label: 'To Lot Size',
    type: 'text',
    model: 'toLotSize',
    required: true,
    disabled: true,
    size: 4
  },
  // {
  //   label: 'Due Date',
  //   type: 'date',
  //   model: 'DueDate',
  //   required: true,
  // },
  ],
  tabData: [
    // {
    // label: 'Work Center Code',
    // type: 'text',
    // model: 'WorkCenterCode',
    // required: false
    // },
    {
      label: 'Work Center',
      type: 'select',
      method: 'api',
      source: 'workCenterInternalID',
      model: 'WorkCenterCode',
      data: [],
      fieldValues: ['WorkCenter', 'WorkCenterInternalID'],
      takeValue: 'WorkCenter',
      showValues: ['WorkCenter'],
      size: 6,
      massKey: "Work_Centre",
    },
    {
      label: 'Control Key',
      type: 'select',
      method: 'api',
      source: 'tlControlkey',
      model: 'CtrlKey',
      data: [],
      fieldValues: ['Steus', 'Txt'],
      takeValue: 'Steus',
      showValues: ['Steus', 'Txt'],
      size: 6,
      massKey: "Control_key",
    },
    {
      label: 'Operation Description',
      type: 'text',
      model: 'OperationDesc',
      required: false,
      size: 6,
      massKey: "Operation_Short_Text",
    },
    {
      label: 'Base Quantity',
      type: 'number',
      model: 'BaseQty',
      required: false,
      size: 6,
      massKey: "Base_Quantity",
    },
    {
      label: 'Setup Time',
      type: 'number',
      model: 'SetupTime',
      required: false,
      size: 6
    },
    {
      label: 'Setup Time Unit',
      type: 'select',
      method: 'api',
      source: 'uomTimeSet',
      model: 'SetupTimeUnit',
      data: [],
      fieldValues: ['Msehi', 'Msehl'],
      takeValue: 'Msehi',
      showValues: ['Msehi', 'Msehl'],
      size: 6
    },
    {
      label: 'Production Time',
      type: 'number',
      model: 'MachineTime',
      required: false,
      size: 6
    },
    {
      label: 'Production Time Unit',
      type: 'select',
      method: 'api',
      source: 'uomTimeSet',
      model: 'MachineTimeUnit',
      data: [],
      fieldValues: ['Msehi', 'Msehl'],
      takeValue: 'Msehi',
      showValues: ['Msehi', 'Msehl'],
      size: 6
    },
    {
      label: 'Teardown',
      type: 'number',
      model: 'Teardown',
      required: false,
      size: 6
    },
    {
      label: 'Teardown Time',
      type: 'select',
      method: 'api',
      source: 'uomTimeSet',
      model: 'TeardownTime',
      data: [],
      fieldValues: ['Msehi', 'Msehl'],
      takeValue: 'Msehi',
      showValues: ['Msehi', 'Msehl'],
      size: 6
    },
  ]
};
