export var basicForm2 = {
    value: 'basic2',
    label: 'Basic Data 2',
    type: 'standard',
    tabData: [
      // {
      //   label: 'Fashion Attribute 1',
      //   type: 'text',
      //   model: 'fashionAttribute1',
      //   size: 4
      // },
      // {
      //   label: 'Fashion Attribute 2',
      //   type: 'text',
      //   model: 'fashionAttribute2',
      //   size: 4
      // },
      // {
      //   label: 'Fashion Attribute 3',
      //   type: 'text',
      //   model: 'fashionAttribute3',
      //   size: 4
      // },
      {
        label: 'Season Year',
        model: 'seasonYear',
        type: 'select',
        method: 'api',
        source: 'seasonYear',
        required: true,
        data: [],
        fieldValues: ['FshSeasonYear'],
        takeValue: 'FshSeasonYear',
        showValues: ['FshSeasonYear'],
        size: 4,
      },
      {
        label: 'Season',
        model: 'season',///ngModel
        type: 'select',
        method: 'api',
        source: 'season',
        required: true,
        data: [],
        fieldValues: ['FshSeason'],
        takeValue: 'FshSeason',
        showValues: ['FshSeason'],
        size: 4,
      },
      {
        label: 'Collection',
        model: 'articleCollection',
        type: 'select',
        method: 'api',
        source: 'collection',
        required: true,
        data: [],
        fieldValues: ['FshCollection'],
        takeValue: 'FshCollection',
        showValues: ['FshCollection'],
        size: 4,
      },
      {
        label: 'Theme',
        model: 'theme',
        type: 'select',
        method: 'api',
        source: 'themes',
        required: true,
        data: [],
        fieldValues: ['FshTheme'],
        takeValue: 'FshTheme',
        showValues: ['FshTheme'],
        size: 4,
      },
    ]
  };
  