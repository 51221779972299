import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-uppercase-modal',
  templateUrl: './uppercase-modal.page.html',
  styleUrls: ['./uppercase-modal.page.scss'],
})

export class UppercaseModalPage implements OnInit {

  @Input() filePath;
  columnsToUpdate: any;
  dataName: any = [];
  val: any = [];
  limit: any;
  name: any;
  error: null;
  concatBy: any;
  values: any;

  constructor(
    private modalCtrl: ModalController,
    public commonService: CommonService,
    private http: HttpClient,
    private navController: NavController
  ) { }

  ngOnInit() {
    this.dataName = this.commonService.getdataName();
    this.val = this.commonService.getselectedPKeys();
    // console.log(typeof(this.dataName))
    // console.log("sonaliiiiii",this.dataName[0])
  }

  close() {
    this.modalCtrl.dismiss();
  }

  createRequest() {
    if (this.columnsToUpdate == null) {
      this.commonService.showToast('error', "Field required");
    } else {
      let formData = {
        operation: "Upper Case",
        dataName: this.filePath,
        columnsToUpdate: this.columnsToUpdate,
      }
      // console.log(formData)
      this.commonService.applyRules(formData).then((res: any) => {
        this.val = res;
        this.commonService.showToast('success', "Table created.");
        this.modalCtrl.dismiss(res)
      },
        error => {
          if (error.status == 200) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);            
            console.log(error)
          }
          if (error.status == 400) {
            if(error.error.msg){
              this.error = error.error.msg;
              this.commonService.dmacshowToast("error", this.error);          
              console.log(error)
            }
            else{
              this.commonService.dmacshowToast("error", "Something went wrong!");   
            }
            }        
          if (error.status == 502) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);         
            console.log(error)
          }
          // this.error = error.message;
          // this.commonService.showToast("error", this.error);
          // console.log(error)
        })
    }
  }

}